import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Sidebar, Header, Footer } from '../../layout-components';
import MainStore from '../../store/MainStore';
import { observer } from 'mobx-react';

@observer
class LeftSidebar extends Component {
  render() {
    return (
      <div className={`app-wrapper app-sidebar-fixed app-header-fixed ${MainStore.openLeftMenu === true ? "app-sidebar-mobile-open" : ""}`}>
        <div>
          <Sidebar />
        </div>
        <div className="app-main">
          <Header />
          <div className="app-content">
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{this.props.children}</div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

LeftSidebar.propTypes = {
  children: PropTypes.node
};

export default LeftSidebar;