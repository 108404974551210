// import React from 'react';
import axios from 'axios';
// import cogoToast from 'cogo-toast';
// import Helper from "./Helper";
// import _ from "lodash";
import Helpers from './Helpers';
// import MainStore from 'store/MainStore';
import { notify } from 'react-notify-toast';

class ServiceHelper {

    // constructor() {
    //     // var user = JSON.parse(localStorage.getItem("user")) || {};
    //     // if (!_.isEmpty(user))
    //     //     axios.defaults.headers = { 'Authorization': `Bearer ${user.token}` };
    // }


    async post(url, data, callback) {
        var user = Helpers.getLocalStorageData("user");
        axios.post(url, data, {
            headers: { 'Authorization': `bearer ${user !== null ? user.token : ""}` }
        }).then(response => {
            return callback(response.status, response.data);
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    notify.show(error.response.data, "warning");
                    // cogoToast.error(error.response.data, { position: "bottom-center", hideAfter: 5 });
                } else if (error.response.status === 404) {
                    notify.show("Erişmeye çalıştığınız hizmet bulunamadı!", "error");
                    // cogoToast.error(error.response.data, { position: "bottom-center" });
                    // localStorage.removeItem("izci-app");
                    // // MainStore.localStorageData = null;

                    // // if (error.response.request.responseURL !== "") {
                    // //     var el = document.createElement('a');
                    // //     el.href = error.response.request.responseURL;
                    // //     history.push(el.pathname);
                    // // } else {
                    // //     cogoToast.error(error.response.data, { position: "bottom-center" });
                    // // }
                } else {
                    if (error.response.status === 401) {

                        if (error.response.headers["token-expired"]) {
                            if (user) {
                                this.post('/api/auth/refreshToken', user, (status, result) => {
                                    if (status === 200) {
                                        localStorage.setItem("user", JSON.stringify(result));
                                        this.get(url, callback);
                                        return;
                                    }
                                });
                            }
                        } else {
                            notify.show("Oturumunuz sonlandırılmıştır!", "warning");
                            window.location.href = "/giris-yap";
                        }
                    }
                    else if (error.response.status === 413) {
                        notify.show("Yüklemeye çalıştığınız dosya boyutu istenilen dosya boyutundan fazla!", "error");
                    } else {
                        alert(3)
                        console.log(error.response)
                    }
                }
            } else if (error.request) {
                if (error.message === "Network Error") {
                    notify.show("Bağlantı hatası oluştu!", "error");
                    window.location.href = "/giris-yap";
                } else {
                    alert(4)
                    console.log(error.response);
                    console.log(error.request);
                    console.log(error.message);
                }
                // cogoToast.error(error.message, { position: "bottom-center" });
            } else {
                alert("Sunucu ile iletişim kurulamdı!");
                console.log(error);
                // console.log('Error', error.message);
            }
            return callback(error.status);
        });
    }

    async get(url, callback) {
        var user = Helpers.getLocalStorageData("user");
        axios.get(url, {
            headers: { 'Authorization': `bearer ${user !== null ? user.token : ""}` }
        }).then(response => {
            return callback(response.status, response.data);
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    alert(1)
                    // cogoToast.warn(error.response.data, { position: "bottom-center", hideAfter: 5 });
                    // window.location.href = "/panel";
                } else if (error.response.status === 401) {
                    // cogoToast.error(error.response.data, { position: "bottom-center" });
                    // localStorage.removeItem("izci-app");
                    // MainStore.localStorageData = null;
                    // window.location.href = "/giris-yap";

                    if (error.response.headers["token-expired"]) {
                        if (user) {
                            this.post('/api/auth/refreshToken', user, (status, result) => {
                                if (status === 200) {
                                    localStorage.setItem("user", JSON.stringify(result));
                                    this.get(url, callback);
                                    return;
                                }
                            });
                        }
                    } else {
                        notify.show("Oturumunuz sonlandırılmıştır!", "warning");
                        window.location.href = "/giris-yap";
                    }

                } else if (error.response.status === 404) {
                    notify.show("Erişmeye çalıştığınız hizmet bulunamadı!", "error");
                    // localStorage.removeItem("izci-app");
                    // MainStore.localStorageData = null;

                    // if (error.response.request.responseURL !== "") {
                    //     var el = document.createElement('a');
                    //     el.href = error.response.request.responseURL;
                    //     history.push(el.pathname);
                    // } else {
                    //     cogoToast.error(error.response.data, { position: "bottom-center" });
                    // }
                } else {
                    if (error.response.status === 405) {
                        notify.show("İşleminiz gerçekleştirilirken hata oluştu! Lütfen tekrar deneyiniz!", "warning");
                    } else {
                        console.log(error.response);
                    }
                }
            } else if (error.request) {
                alert("Sunucu ile iletişim kurulamdı!");
                console.log(error);
                // console.log("error.request", error.request);
            } else {
                alert(6)
                console.log(error);
                // cogoToast.error(error.message, { position: "bottom-center", hideAfter: 5 });
            }
            return callback(error.status);
        });
    }

}
export default new ServiceHelper();