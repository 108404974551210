import React, { Component } from 'react'
import './MainCarousel.scss';
import { observer } from 'mobx-react';
import MainStore from '../../../store/MainStore';
import _ from 'lodash';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, Container } from 'reactstrap';
import { toJS } from 'mobx';
import { NavLink } from 'react-router-dom';

@observer
class MainCarousel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeIndex: 0,
            animating: false
        }
    }

    next = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === MainStore.mainCarouselData.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? MainStore.mainCarouselData.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        return MainStore.showMainCarousel && !_.isEmpty(MainStore.mainCarouselData)
            ? <Carousel
                activeIndex={this.state.activeIndex}
                next={this.next}
                previous={this.previous}
                className="main-carousel">
                <CarouselIndicators items={MainStore.mainCarouselData} activeIndex={this.state.activeIndex} onClickHandler={this.goToIndex} />
                {
                    MainStore.mainCarouselData.map((item, index) => <CarouselItem key={index}
                        onExiting={() => this.setState({ animating: true })}
                        onExited={() => this.setState({ animating: false })}
                        key={item.src}>
                        <NavLink to={`/detay?yaziId=${item.id}`}>
                            <img src={item.src} alt={item.altText} />
                            <CarouselCaption captionText={item.caption} captionHeader={item.altText} />
                        </NavLink>
                    </CarouselItem>)
                }
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
            : ""
    }
}

export default MainCarousel