import React, { Fragment } from 'react';

function Footer() {
  return (
    <Fragment>
      <div className="app-footer text-black-50">
        <div className="app-footer--first">
          2020 - Tüm hakları saklıdır. ©
        </div>
        <div className="app-footer--second">
          {/* Geliştirici <span className="text-danger px-1">❤</span> by{' '}
          <a href="https://linkedin.com/in/mehmeteminkaradag" target="_blank" title="UiFort.com" rel="noopener noreferrer">
            Mehmet Emin KARADAĞ
          </a> */}
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
