import React from 'react';
import './CustomLayout.scss';
import { Route } from 'react-router-dom';
import SimpleReactLightbox from "simple-react-lightbox";
import Footer from './footer/Footer';
import Header from './header/Header';
import MainCarousel from '../../components/main-carousel/MainCarousel';
import LeftSidebar from './left-sidebar/LeftSidebar';

const CustomLayout = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <div>
                <SimpleReactLightbox>
                    <Header />
                    <LeftSidebar />
                    <MainCarousel />
                    <div id="custom-layout">
                        <Component {...matchProps} />
                    </div>
                    <Footer />
                </SimpleReactLightbox>
            </div>
        )} />
    )
};

export default CustomLayout;