import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-widgets/dist/css/react-widgets.css';
import Notifications from 'react-notify-toast';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<div>
    <Notifications />
    <App />
</div>, document.getElementById('root'));

registerServiceWorker();

