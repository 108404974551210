import React, { Component } from 'react';
import ServiceHelper from '../../../../helpers/ServiceHelper';
import './Footer.scss';
import _ from 'lodash';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import MainStore from '../../../../store/MainStore';
import { observer } from 'mobx-react';

@observer
class Footer extends Component {
    // constructor(props) {
    //     super(props)
    //     this.state = {
    //         isLoading: false,
    //         menus: []
    //     }
    // }

    // componentDidMount() {
    //     this.setState({ isLoading: true });
    //     ServiceHelper.get('/api/home/getHeaderMenu', (status, result) => {
    //         if (status === 200) {
    //             this.setState({ menus: result });
    //         }
    //         this.setState({ isLoading: false });
    //     });
    // }


    render() {
        return (
            <>
                <div id="footer-menu-container">
                    {
                        // this.state.isLoading
                        //     ? "Yükleniyor..."
                        //     : 
                        _.isEmpty(MainStore.menus)
                            ? "Menü bilgisi alınamadı!"
                            : <Container>
                                <div className="d-flex flex-wrap justify-content-between">
                                    {
                                        MainStore.menus.map((item, index) => <div key={index} className="item">
                                            <NavLink key={index} to={`/liste?kategoriId=${item.id}`} className="footer-menu-item font-weight-bold">{item.name}</NavLink>
                                            {/* <span key={index} className="footer-menu-item">{item.name}</span> */}
                                            {
                                                !_.isEmpty(item.childrens) && <ul className="childrens">
                                                    {
                                                        item.childrens.map((children, index2) => {
                                                            if (children.name === "Abideler") {
                                                                return <li key={index2}><NavLink to="/abideler">{children.name}</NavLink></li>
                                                            } else {
                                                                return <li key={index2}><NavLink to={`/liste?kategoriId=${children.id}`}>{children.name}</NavLink></li>
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            }
                                        </div>)
                                    }
                                    <NavLink to="/indeks" className="footer-menu-item font-weight-bold index">İndeks</NavLink>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between pb-3">
                                    <NavLink to="/hakkimizda" className="font-weight-bold">Hakkımızda</NavLink>
                                    <NavLink to="/yayin-politikasi" className="font-weight-bold">Yayın Politiklarımız</NavLink>
                                    <a href="mailto:info@mimaridusunce.com" className="font-weight-bold">İletişim</a>
                                </div>
                            </Container>
                    }
                </div>
                <div id="footer2" className="d-flex justify-content-between">
                    <span>© 2020</span>
                    <span>Tüm hakları saklıdır.</span>
                    {/* <div> by<span className="text-danger px-1">❤</span> <a href="https://tr.linkedin.com/in/mehmeteminkaradag" target="_blank" title="Mehmet Emin KARADAĞ" rel="noopener noreferrer" className="profile">Mehmet Emin KARADAĞ</a></div> */}
                </div>
            </>

        )
    }
}

export default Footer;