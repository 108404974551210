import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';

class SidebarHeader extends Component {
  render() {
    return (
      <Fragment>
        <div className="app-sidebar--header">
          <div className="nav-logo">
            <Link
              to="/anasayfa"
              title="Mimari Düşünce Platformu">
              <span>Mimari Düşünce Platformu</span>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SidebarHeader;
