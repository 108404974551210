import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

// Layout Blueprints

import { LeftSidebar, PresentationLayout } from './layout-blueprints';
import CustomLayout from './public/pages/layout/CustomLayout';
import SendedArticles from './private/pages/editorial-management/sended-articles/SendedArticles';
const LandingPage = lazy(() => import('./example-pages/LandingPage'));
const DashboardDefault = lazy(() => import('./example-pages/DashboardDefault'));


const HomePage = lazy(() => import('./public/pages/home/Home'));
const AboutPage = lazy(() => import('./public/pages/about/About'));
const YayinPolitikasiPage = lazy(() => import('./public/pages/yayin-politikasi/YayinPolitikasi'));
const ArticleDetailPage = lazy(() => import('./public/pages/article-detail/ArticleDetail'));
const ArticleListtPage = lazy(() => import('./public/pages/article-list/ArticleList'));
const TagListPage = lazy(() => import('./public/pages/tag-list/TagList'));
const AbidelerPage = lazy(() => import('./public/pages/abideler/Abideler'));
const IndeksPage = lazy(() => import('./public/pages/indeks/Indeks'));


const RegisterPage = lazy(() => import('./public/pages/authentication/Register'));
const LoginPage = lazy(() => import('./public/pages/authentication/Login'));
const EmailActivationPage = lazy(() => import('./public/pages/authentication/EmailActivation'));
const EmailCodeActivationPage = lazy(() => import('./public/pages/authentication/EmailCodeActivation'));
const ForgotPasswordPage = lazy(() => import('./public/pages/authentication/ForgotPassword'));
const RoleManagementPage = lazy(() => import('./private/pages/role-management/RoleManagement'));
const UserManagementPage = lazy(() => import('./private/pages/user-management/UserManagement'));
const SendArticlePage = lazy(() => import('./private/pages/editorial-management/send-article/SendArticle'));
const CategoryManagementPage = lazy(() => import('./private/pages/editorial-management/category-management/CategoryManagement'));
const ArticlePage = lazy(() => import('./private/pages/article-management/Article'));
const ArticleListPage = lazy(() => import('./private/pages/article-management/article-list/ArticleList'));
const GalleryPage = lazy(() => import('./private/pages/gallery-management/Gallery'));
const GalleryListPage = lazy(() => import('./private/pages/gallery-management/gallery-list/GalleryList'));
const UserSettingsPage = lazy(() => import('./private/pages/user-management/user-settings/UserSettings'));
const TagManagementPage = lazy(() => import('./private/pages/editorial-management/tag-management/TagManagement'));
const PeriodsFunctionsPage = lazy(() => import('./private/pages/editorial-management/periods-functions/PeriodsFunctions'));
const SendedArticlesPage = lazy(() => import('./private/pages/editorial-management/sended-articles/SendedArticles'));
const CommentListPage = lazy(() => import('./private/pages/editorial-management/comments/CommentList'));
const PendignCommentsPage = lazy(() => import('./private/pages/editorial-management/comments/PendingComments'));
const OthersPage = lazy(() => import('./private/pages/others/Others'));
const ArticlePreviewPage = lazy(() => import('./private/pages/article-management/article-preview/ArticlePreview'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <AnimatePresence>
      <Suspense
        fallback={
          <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
            <div className="w-50 mx-auto">
              Lütfen bekleyiniz...
            </div>
          </div>
        }>
        <Switch>
          <Redirect exact from="/" to="/anasayfa" />
          <Route path={[
            '/anasayfa',
            '/hakkimizda',
            '/yayin-politikasi',
            '/detay',
            '/liste',
            '/etiket',
            '/abideler',
            '/indeks',
            '/onizleme'
          ]}>
            <PresentationLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <CustomLayout path="/anasayfa" component={HomePage} />
                  <CustomLayout path="/hakkimizda" component={AboutPage} />
                  <CustomLayout path="/detay" component={ArticleDetailPage} />
                  <CustomLayout path="/liste" component={ArticleListtPage} />
                  <CustomLayout path="/etiket" component={TagListPage} />
                  <CustomLayout path="/abideler" component={AbidelerPage} />
                  <CustomLayout path="/yayin-politikasi" component={YayinPolitikasiPage} />
                  <CustomLayout path="/indeks" component={IndeksPage} />
                  <CustomLayout path="/onizleme" component={ArticlePreviewPage} />
                  {/* <Route path="/" component={LandingPage} /> */}
                </motion.div>
              </Switch>
            </PresentationLayout>
          </Route>
          <Route path="/kaydol" component={RegisterPage} />
          <Route path="/giris-yap" component={LoginPage} />
          <Route path="/e-posta-onayi" component={EmailActivationPage} />
          <Route path="/e-posta-onay-kodu" component={EmailCodeActivationPage} />
          <Route path="/sifremi-unuttum" component={ForgotPasswordPage} />

          <Route
            path={[
              '/panel',
              '/rol-yonetimi',
              '/kullanici-yonetimi',
              '/yazi-proje',
              '/kategori-islemleri',
              '/yazi-islemleri',
              '/yazi-listesi',
              '/gorsel-ekle',
              '/galeri',
              '/kullanici-ayarlari',
              '/etiket-yonetimi',
              '/donemler-ve-islevler',
              '/gelen-yazilar',
              '/yorumlar',
              '/bekleyen-yorumlar',
              '/diger-islemler'
            ]}>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route exact path="/panel" component={DashboardDefault} />
                  <Route path="/rol-yonetimi" component={RoleManagementPage} />
                  <Route path="/kullanici-yonetimi" component={UserManagementPage} />
                  <Route path="/yazi-proje" component={SendArticlePage} />
                  <Route path="/kategori-islemleri" component={CategoryManagementPage} />
                  <Route exact path="/yazi-islemleri" component={ArticlePage} />
                  <Route exact path="/yazi-islemleri/:id" component={ArticlePage} />
                  <Route path="/yazi-listesi" component={ArticleListPage} />
                  <Route path="/gorsel-ekle" component={GalleryPage} />
                  <Route path="/galeri" component={GalleryListPage} />
                  <Route path="/kullanici-ayarlari" component={UserSettingsPage} />
                  <Route path="/etiket-yonetimi" component={TagManagementPage} />
                  <Route path="/donemler-ve-islevler" component={PeriodsFunctionsPage} />
                  <Route path="/gelen-yazilar" component={SendedArticlesPage} />
                  <Route path="/yorumlar" component={CommentListPage} />
                  <Route path="/bekleyen-yorumlar" component={PendignCommentsPage} />
                  <Route path="/diger-islemler" component={OthersPage} />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
