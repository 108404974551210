import React, { Fragment, Component } from 'react';
import { SidebarHeader, SidebarMenu } from '../../layout-components';
import MainStore from '../../store/MainStore';

class Sidebar extends Component {
  toggleSidebarMobile = () => {
    MainStore.openLeftMenu = !MainStore.openLeftMenu;
  };

  render() {
    return (
      <Fragment>
        <div className="app-sidebar app-sidebar--dark">
          <SidebarHeader />
          <div className="app-sidebar--content">
            <SidebarMenu />
          </div>
        </div>
        <div
          onClick={this.toggleSidebarMobile}
          className={`${MainStore.openLeftMenu ? "is-active" : ""} app-sidebar-overlay`}
        />
      </Fragment>
    );
  }
}
export default Sidebar;
