// import _ from "lodash";

class Helpers {
  getLocalStorageData(name) {
    var localStorageData = localStorage.getItem(name);
    if (localStorageData) {
      return JSON.parse(localStorageData);
    }
    else {
      return null;
    }
  }

  isAuthenticate() {
    return this.getLocalStorageData("user") === null ? false : true;
  }

  getRoleName = (role) => {
    if (role) {
      switch (role) {
        case "User":
          return "Kullanıcı";
        case "Author":
          return "Yazar";
        case "SuperAdmin":
          return "Süper Yönetici";
        default:
          return role;
      }
    }
  }
}

export default new Helpers();