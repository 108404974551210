import React, { Fragment, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logo.jpg';
import Helpers from '../../helpers/Helpers';
import _ from 'lodash';
import JwtDecode from 'jwt-decode';
import { observer } from 'mobx-react';
import MainStore from '../../store/MainStore';

@observer
class HeaderUserbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {}
    }
  }

  componentDidMount() {
    var localStorageData = Helpers.getLocalStorageData("user");
    if (!_.isEmpty(localStorageData)) {
      var user = JwtDecode(localStorageData.token);
      this.setState({ user: user });
      MainStore.user = user;
    }
  }

  render() {
    return (
      <Fragment>
        <UncontrolledDropdown className="user-box position-relative ml-2">
          <DropdownToggle
            color="link"
            className="p-0 text-left d-flex align-items-center">
            <div className="d-block d-44 rounded-sm overflow-hidden">
              <img src={logo} className="img-fluid" alt="Mimari Düşünce Platformu" />
            </div>
            <div className="d-none d-xl-block pl-2">
              <div className="font-weight-bold">{this.state.user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]}</div>
              <span className="text-black-50">{Helpers.getRoleName(this.state.user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"])}</span>
            </div>
            <span className="pl-1 pl-xl-3">
              <FontAwesomeIcon
                icon={['fas', 'angle-down']}
                className="opacity-5"
              />
            </span>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
            <ListGroup flush className="text-left bg-transparent">
              <ListGroupItem className="rounded-top">
                <Nav pills className="nav-neutral-primary flex-column">
                  {/* <NavItem>
                    <NavLink href="#/" onClick={e => e.preventDefault()}>
                      My Account
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#/" onClick={e => e.preventDefault()}>
                      Profile settings
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink to="/kullanici-ayarlari">
                      Ayarlar
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/giris-yap">
                      Çıkış Yap
                    </NavLink>
                  </NavItem>
                </Nav>
              </ListGroupItem>
            </ListGroup>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    );
  }
}

export default HeaderUserbox;
