import React, { Fragment, Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UncontrolledTooltip, Button } from 'reactstrap';

class PageTitle extends Component {
  render() {
    return (
      <Fragment>
        <div className="app-page-title">
          <div>
            <div className="app-page-title--first">
              <div className="app-page-title--heading">
                <h1>{this.props.titleHeading}</h1>
                <div className="app-page-title--description">
                  {this.props.titleDescription}
                </div>
              </div>
            </div>
          </div>
          {
            this.props.onClick && <div className="d-flex align-items-center mt-3 mt-lg-0">
              <Button size="sm" color="success" id="AddEntryTooltip20" onClick={this.props.onClick}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon
                    icon={['fas', 'plus']}
                    className="opacity-8 font-size-xs"
                  />
                </span>
              </Button>
              <UncontrolledTooltip target="AddEntryTooltip20">
                {this.props.tooltip}
              </UncontrolledTooltip>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

export default PageTitle;
