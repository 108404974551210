import React, { Component } from 'react';
import './SendedArticles.scss';
import { Row, Col, Card, CardBody, CardTitle, UncontrolledAlert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import PageTitle from './../../../../layout-components/PageTitle/index';
import Helpers from '../../../../helpers/Helpers';
import ServiceHelper from '../../../../helpers/ServiceHelper';

class SendedArticles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isloading: false,
            articles: [],
            showDeleteModal: false,
            selectedArticle: {}
        }
    }

    componentDidMount() {
        if (!Helpers.isAuthenticate()) {
            this.props.history.push("/giris-yap");
        }
        this.getData();
    }

    getData = () => {
        this.setState({ isloading: true });
        ServiceHelper.get("/api/externalArticle/getAll", (status, result) => {
            if (status === 200) {
                this.setState({ articles: result });
            }
            this.setState({ isloading: false });
        })
    }

    deleteArticle = (article) => {
        this.setState({ isloading: true });
        ServiceHelper.post('/api/externalArticle/delete', article, (status, result) => {
            if (status === 200) {
                this.toggleDeleteModal({});
                this.getData();
            }
            this.setState({ isloading: false });
        });
    }

    toggleDeleteModal = (item) => {
        this.setState({ selectedArticle: item, showDeleteModal: !this.state.showDeleteModal });
    }

    render() {
        const columns = [
            {
                name: 'Başlık',
                selector: 'title',
                sortable: true,
            },
            {
                name: 'Kategori',
                selector: 'categoryName',
                sortable: true
            },
            {
                name: 'Yazar',
                selector: 'user',
                sortable: true,
            },
            {
                name: 'E - Posta',
                selector: 'email',
                sortable: true,
            },
            {
                name: 'Tarih',
                selector: 'date',
                sortable: true,
                cell: row => <span>{moment(row.date).format("DD.MM.YYYY HH:mm:ss")}</span>
            },
            {
                name: 'İşlem',
                selector: '',
                right: true,
                cell: row => <>
                    <Button size="sm" color="link" className="m-2 btn-link-primary m-0 p-1" onClick={() => window.location.href = row.fileUrl}>
                        <span>İndir</span>
                    </Button>
                    <Button size="sm" color="link" className="m-2 btn-link-danger m-0 p-1" onClick={() => this.toggleDeleteModal(row)}>
                        <span>Sil</span>
                    </Button>
                </>
            },
        ];
        return (
            <div id="sended-articles-list-container">
                <PageTitle
                    titleHeading="Gelen Yazılar"
                    titleDescription="Platformda yayınlanmak üzere üyeler tarafınfan gönderilen yazıları buradan görntüleyebilirsiniz." />

                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <CardTitle className="font-weight-bold font-size-lg mb-4">Yazı Listesi</CardTitle>
                                {
                                    this.state.isloading
                                        ? "Yükleniyor..."
                                        : this.state.articles.length === 0
                                            ? <UncontrolledAlert color="primary">Herhangi bir sonuç bulunamadı!</UncontrolledAlert>
                                            : <DataTable
                                                noHeader={true}
                                                columns={columns}
                                                data={this.state.articles}
                                                expandableRows
                                                expandableRowsComponent={<ExpandableComponent />} />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {
                    this.state.showDeleteModal && <Modal zIndex={2000} centered isOpen={this.state.showDeleteModal}>
                        <ModalHeader toggle={() => this.toggleDeleteModal()}>Yazı Sil</ModalHeader>
                        <ModalBody>
                            {`${this.state.selectedArticle.title}`} yazısını silmek istediğinizden emin misiniz?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="link" className="btn-link-dark float-right" onClick={() => this.toggleDeleteModal()}>İptal</Button>
                            <Button color="danger" className="float-right" onClick={() => this.deleteArticle(this.state.selectedArticle)}>Sil</Button>
                        </ModalFooter>
                    </Modal>
                }
            </div>
        )
    }
}

const ExpandableComponent = ({ data }) => <div className="p-3">
    <div className="border-bottom">Açıklama:</div>
    <p className="mt-2">{data.description}</p>
</div>

export default SendedArticles;