import React, { Component } from 'react'
import './LeftSidebar.scss';
import { observer } from 'mobx-react';
import MainStore from '../../../../store/MainStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MetisMenu from 'react-metismenu';
import _ from "lodash";
import RouterLink from '../../../../layout-components/ReactMetismenuRouterLink';

@observer
class LeftSidebar extends Component {
    render() {

        const content = [];
        MainStore.menus.map((item) => {
            var parent = {
                label: item.name,
                to: `/liste?kategoriId=${item.id}`
            }
            if (!_.isEmpty(item.childrens)) {
                var childrens = [];
                item.childrens.map((children) => childrens.push({
                    label: children.name,
                    to: children.name === "Abideler" ? "/abideler" : `/liste?kategoriId=${children.id}`
                }));
                parent["content"] = childrens;
            }
            content.push(parent)
        });

        return (
            <div className={`left-sidebar-container shadow ${MainStore.showLeftMenu ? "d-block" : "d-none"}`}>
                <div className="d-flex justify-content-between border-bottom pb-2 mb-3">
                    <span className="font-weight-bold">MENÜ</span>
                    <FontAwesomeIcon icon={["fas", "times"]} size="lg" className="close-menu-button" onClick={() => MainStore.showLeftMenu = !MainStore.showLeftMenu} />
                </div>
                <div className="sidebar-navigation">
                    <MetisMenu
                        content={content}
                        LinkComponent={RouterLink}
                        activeLinkFromLocation
                        iconNamePrefix=""
                        noBuiltInClassNames={true}
                        classNameItemActive="active"
                        classNameIcon="sidebar-icon"
                        iconNameStateVisible="sidebar-icon-indicator"
                        iconNameStateHidden="sidebar-icon-indicator"
                        classNameItemHasVisibleChild="submenu-open"
                        classNameItemHasActiveChild="active"
                        classNameStateIcon="pe-7s-angle-down"
                    />
                </div>
            </div>
        )
    }
}

export default LeftSidebar