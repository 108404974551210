import React, { Fragment, Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RouterLink from '../ReactMetismenuRouterLink';
import MetisMenu from 'react-metismenu';
import MainStore from '../../store/MainStore';
import { observer } from 'mobx-react';
import _ from 'lodash';

const userMenu = [
  {
    label: 'Anasayfa',
    icon: 'pe-7s-home',
    description: 'Anasayfa',
    to: '/anasayfa'
  },
  {
    label: 'Yazı - Proje İşlemleri',
    icon: 'pe-7s-note2',
    content: [
      {
        label: 'Yazı Gönder',
        icon: 'pe-7s-note2',
        description: 'Yazı Gönder',
        to: '/yazi-proje'
      }
    ]
  }
];

const authorMenu = [
  {
    label: 'Anasayfa',
    icon: 'pe-7s-home',
    description: 'Anasayfa',
    to: '/anasayfa'
  },
  {
    label: 'Yazı İşleri Yönetimi',
    icon: 'pe-7s-note',
    content: [
      {
        label: 'Yazı İşlemleri',
        description: 'Yazı İşlemleri',
        to: '/yazi-islemleri'
      },
      {
        label: 'Yazı Listesi',
        description: 'Yazı Listesi',
        to: '/yazi-listesi'
      },
      {
        label: 'Etiket Yönetimi',
        description: 'Etiket Yönetimi',
        to: '/etiket-yonetimi'
      }
    ]
  },
  {
    label: 'Galeri Yönetimi',
    icon: 'pe-7s-photo',
    content: [
      {
        label: 'Yeni Görsel',
        description: 'Yeni Görsel',
        to: '/gorsel-ekle'
      },
      {
        label: 'Galeri',
        description: 'Galeri',
        to: '/galeri'
      }
    ]
  }
];

const editorMenu = [
  {
    label: 'Anasayfa',
    icon: 'pe-7s-home',
    description: 'Anasayfa',
    to: '/anasayfa'
  },
  {
    label: 'Kullanıcı ve Rol Yönetimi',
    icon: 'pe-7s-id',
    content: [
      {
        label: 'Kullanıcı Yönetimi',
        description: 'Kullanıcı Yönetimi',
        to: '/kullanici-yonetimi'
      },
      {
        label: 'Rol Yönetimi',
        description: 'Rol Yönetimi',
        to: '/rol-yonetimi'
      }
    ]
  },
  {
    label: 'Yazı İşleri Yönetimi',
    icon: 'pe-7s-note',
    content: [
      {
        label: 'Yazı İşlemleri',
        description: 'Yazı İşlemleri',
        to: '/yazi-islemleri'
      },
      {
        label: 'Yazı Listesi',
        description: 'Yazı Listesi',
        to: '/yazi-listesi'
      },
      {
        label: 'Gelen Yazılar',
        description: 'Gelen Yazılar',
        to: '/gelen-yazilar'
      },
      {
        label: 'Kategori Yönetimi',
        description: 'Kategori Yönetimi',
        to: '/kategori-islemleri'
      },
      {
        label: 'Etiket Yönetimi',
        description: 'Etiket Yönetimi',
        to: '/etiket-yonetimi'
      },
      {
        label: 'Dönemler ve İşlevler',
        description: 'Dönemler ve İşlevler',
        to: '/donemler-ve-islevler'
      }
    ]
  },
  {
    label: 'Yorumlar',
    icon: 'pe-7s-comment',
    content: [
      {
        label: 'Yorumlar',
        description: 'Yorumlar',
        to: '/yorumlar'
      },
      // {
      //   label: 'Onay Bekleyenler',
      //   description: 'Onay Bekleyenler',
      //   to: '/bekleyen-yorumlar'
      // }
    ]
  },
  {
    label: 'Galeri Yönetimi',
    icon: 'pe-7s-photo',
    content: [
      {
        label: 'Yeni Görsel',
        description: 'Yeni Görsel',
        to: '/gorsel-ekle'
      },
      {
        label: 'Galeri',
        description: 'Galeri',
        to: '/galeri'
      }
    ]
  },
  {
    label: 'Yazı - Proje İşlemleri',
    icon: 'pe-7s-note2',
    content: [
      {
        label: 'Yazı Gönder',
        description: 'Yazı Gönder',
        to: '/yazi-proje'
      }
    ]
  },
  {
    label: 'Diğer İşlemler',
    icon: 'pe-7s-shuffle',
    description: 'Diğer İşlemler',
    to: '/diger-islemler'
  }
];


@observer
class SidebarMenu extends Component {

  getMenuByRole = (role) => {
    switch (role) {
      case "Author":
        return authorMenu;
      case "Editor":
        return editorMenu;
      case "SuperAdmin":
        return editorMenu;
      default:
        return userMenu;
    }
  }

  render() {
    return (
      <Fragment>
        {
          !_.isEmpty(MainStore.user) && <PerfectScrollbar>
            <div className="sidebar-navigation">
              <div className="sidebar-header">
                <span>Menü</span>
              </div>
              <MetisMenu
                content={this.getMenuByRole(MainStore.user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"])}
                LinkComponent={RouterLink}
                activeLinkFromLocation
                iconNamePrefix=""
                noBuiltInClassNames={true}
                classNameItemActive="active"
                classNameIcon="sidebar-icon"
                iconNameStateVisible="sidebar-icon-indicator"
                iconNameStateHidden="sidebar-icon-indicator"
                classNameItemHasVisibleChild="submenu-open"
                classNameItemHasActiveChild="active"
                classNameStateIcon="pe-7s-angle-down"
              />
            </div>
          </PerfectScrollbar>
        }
      </Fragment>
    );
  }
}

export default SidebarMenu;
