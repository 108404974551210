import { observable } from "mobx";

class MainStore {
    @observable openLeftMenu = false;
    @observable user = {};
    @observable showMainCarousel = false;
    @observable mainCarouselData = [];
    @observable menus = [];
    @observable showLeftMenu = false;

    @observable articleDetailGallery = [];
}

export default new MainStore();