import React, { Fragment, Component } from 'react';
// import { Button } from 'reactstrap';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import MainStore from '../../store/MainStore';

class Header extends Component {
  toggleSidebarMobile = () => {
    MainStore.openLeftMenu = !MainStore.openLeftMenu;
  };

  render() {
    return (
      <Fragment>
        <div className="app-header">
          <div className="app-header--pane">
            <button
              className={`${MainStore.openLeftMenu ? "is-active" : ""} navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn`}
              onClick={this.toggleSidebarMobile}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
            {/* <Button
              tag="a"
              href="https://uifort.com/template/bamburgh-react-admin-dashboard-reactstrap-free"
              target="_blank"
              size="sm"
              color="neutral-success"
              className="ml-3 mr-3 btn-transition-none">
              Download now
            </Button>
            <Button
              tag="a"
              href="https://uifort.com/template/bamburgh-react-admin-dashboard-reactstrap-pro"
              target="_blank"
              size="sm"
              color="neutral-warning"
              className="transition-none">
              View PRO Version
            </Button> */}
          </div>
          <div className="app-header--pane">
            <HeaderUserbox />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Header;
