import React, { Component } from 'react';
import './Header.scss';
import { observer } from 'mobx-react';
import { Link, NavLink } from 'react-router-dom';
import Helpers from '../../../../helpers/Helpers';
import MainStore from '../../../../store/MainStore';
import HeaderTopMenu from './header-top-menu/HeaderTopMenu';
import JwtDecode from 'jwt-decode';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

@observer
class Header extends Component {

    fillMainStoreUser = () => {
        var localStorageData = Helpers.getLocalStorageData("user");
        if (!_.isEmpty(localStorageData)) {
            var user = JwtDecode(localStorageData.token);
            this.setState({ user: user });
            MainStore.user = user;
        }
    }

    renderNavlink = () => {
        if (_.isEmpty(MainStore.user)) {
            this.fillMainStoreUser();
        }

        var role = MainStore.user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        switch (role) {
            case "Editor":
                return <NavLink to="/yazi-listesi">Yönetim Paneli</NavLink>
            case "User":
                return <NavLink to="/yazi-proje">Yönetim Paneli</NavLink>
            case "Author":
                return <NavLink to="/yazi-listesi">Yönetim Paneli</NavLink>
            case "SuperAdmin":
                return <NavLink to="/panel">Yönetim Paneli</NavLink>
        }
    }

    render() {
        return (
            <>
                <div id="main-header-container">
                    <div className="d-flex d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={["fas", "bars"]} size="lg" className="left-hamburger-menu" onClick={() => MainStore.showLeftMenu = !MainStore.showLeftMenu} />
                        </div>
                        {
                            Helpers.isAuthenticate()
                                ? this.renderNavlink()
                                : <div>
                                    <Link to="/giris-yap">Giriş Yap</Link>
                                    <span className="ml-2 mr-2">|</span>
                                    <Link to="/kaydol">Üye Ol</Link>
                                </div>
                        }

                    </div>
                </div>
                <div className="text-center mr-3 ml-3">
                    <NavLink to="/anasayfa">
                        <img src="/images/logo.png" id="main-logo" />
                    </NavLink>
                    <div id="slogan">Mimari, bir milletin kendini ifade ederken maddeye nakşettiği serüvendir.</div>
                </div>
                <HeaderTopMenu />
            </>
        )
    }
}

export default Header;