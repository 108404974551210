import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import ServiceHelper from '../../../../../helpers/ServiceHelper';
import './HeaderTopMenu.scss';
import _ from 'lodash';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Container,
    NavbarBrand,
    DropdownItem
} from 'reactstrap';
import MainStore from '../../../../../store/MainStore';
import { observer } from 'mobx-react';

@observer
class HeaderTopMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false
        }
    }

    componentDidMount() {
        if (_.isEmpty(MainStore.menus)) {
            this.setState({ isLoading: true });
            ServiceHelper.get('/api/home/getHeaderMenu', (status, result) => {
                if (status === 200) {
                    MainStore.menus = result;
                }
                this.setState({ isLoading: false });
            });
        }
    }

    toggleOpen = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <div id="header-top-menu-container">
                {
                    this.state.isLoading
                        ? "Yükleniyor..."
                        : _.isEmpty(MainStore.menus)
                            ? "Menü bilgisi alınamadı!"
                            : <Container>
                                {/* <div className="d-flex justify-content-between">
                                    {
                                        this.state.menus.map((item, index) => <NavLink key={index} to={`/liste?kategoriId=${item.id}`} className="header-top-menu-item">{item.name}</NavLink>)
                                    }
                                    <NavLink to="/indeks" className="header-top-menu-item">İndeks</NavLink>
                                </div> */}
                                <div>
                                    <Navbar light expand="md">
                                        <NavbarBrand href="/"></NavbarBrand>
                                        <NavbarToggler onClick={this.toggleOpen} />
                                        <Collapse isOpen={this.state.isOpen} navbar>
                                            <Nav className="d-flex justify-content-between w-100" navbar>
                                                {
                                                    MainStore.menus.map((item, index) =>
                                                        !_.isEmpty(item.childrens)
                                                            ? <UncontrolledDropdown key={index} nav inNavbar>
                                                                <DropdownToggle nav caret>
                                                                    <NavLink className="drop-menu-link" to={`/liste?kategoriId=${item.id}`} onClick={(e) => e.stopPropagation()}>{item.name}</NavLink>
                                                                </DropdownToggle>
                                                                <DropdownMenu right>
                                                                    {
                                                                        item.childrens.map((children, index2) => <DropdownItem className="active" tag={NavLink} key={index2} to={`${children.name !== "Abideler" ? `/liste?kategoriId=${children.id}` : "/abideler"}`}>
                                                                            {children.name}
                                                                        </DropdownItem>)
                                                                    }
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            : <NavItem key={index}>
                                                                <NavLink className="header-top-menu-item" to={`/liste?kategoriId=${item.id}`}>{item.name}</NavLink>
                                                            </NavItem>)
                                                }
                                                <NavItem>
                                                    <NavLink className="header-top-menu-item" to="/indeks">indeks</NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Collapse>
                                    </Navbar>
                                </div>


                            </Container>

                    //     <Navbar color="light" light expand="md">
                    //     <NavbarBrand href="/">reactstrap</NavbarBrand>
                    //     <NavbarToggler onClick={this.toggleOpen} />
                    //     <Collapse isOpen={this.state.isOpen} navbar>
                    //         <Nav className="mr-auto" navbar>
                    //             <NavItem>
                    //                 <NavLink to="/components/">Components</NavLink>
                    //             </NavItem>
                    //             <NavItem>
                    //                 <NavLink to="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
                    //             </NavItem>
                    //             <UncontrolledDropdown nav inNavbar>
                    //                 <DropdownToggle nav caret>
                    //                     Options
                    //       </DropdownToggle>
                    //                 <DropdownMenu right>
                    //                     <DropdownItem>
                    //                         Option 1
                    //         </DropdownItem>
                    //                     <DropdownItem>
                    //                         Option 2
                    //         </DropdownItem>
                    //                     <DropdownItem divider />
                    //                     <DropdownItem>
                    //                         Reset
                    //         </DropdownItem>
                    //                 </DropdownMenu>
                    //             </UncontrolledDropdown>
                    //         </Nav>
                    //     </Collapse>
                    // </Navbar>


                }
            </div>
        )
    }
}

export default HeaderTopMenu